<template>
  <menu-root v-slot="{ open }" as="div" class="relative flex">
    <menu-button :class="buttonClass">
      <slot v-bind="{ open }">
        <app-icon-button
          icon="ph:dots-three-vertical-bold"
          :icon-size="iconSize"
          label="Menu"
        />
      </slot>
    </menu-button>

    <client-only>
      <teleport :disabled="!isMobileView" to="body">
        <app-fade-transition>
          <div
            v-show="isMobileView && open"
            class="fixed z-[100] inset-0 bg-black/30"
          />
        </app-fade-transition>

        <transition
          enter-active-class="transition"
          enter-from-class="opacity-0 translate-y-1"
          leave-active-class="transition"
          leave-to-class="opacity-0 translate-y-1"
        >
          <menu-items
            as="ul"
            class="text-black shadow-lg text-sm absolute z-[100] min-w-[--minWidth] bg-white rounded"
            :class="[
              positionClasses,
              {
                '!fixed !bottom-0 !left-0 !right-0 !top-auto !rounded-b-none !rounded-t-lg pb-4':
                  isMobileView,
              },
            ]"
            :style="{ '--minWidth': `${menuMinWidth}px` }"
          >
            <template v-if="$slots.prepend">
              <div class="p-4">
                <slot name="prepend" />
              </div>
              <app-divider />
            </template>

            <div class="p-2">
              <template v-for="(item, index) in items">
                <app-divider
                  v-if="item.divider"
                  :key="`divider_${index}`"
                  class="my-2 -ml-2 -mr-2"
                />
                <menu-item
                  v-else
                  :key="item.label"
                  v-slot="{ active, close }"
                  as="li"
                >
                  <component
                    :is="item.href ? 'a' : item.to ? NuxtLink : 'button'"
                    active-class="bg-primary-50 text-primary"
                    class="font-semibold flex text-left items-start p-2 gap-4 min-w-full rounded transition active:scale-95"
                    :class="{
                      '!bg-primary-50/70': active,
                      'text-primary font-semibold bg-primary-50': item.selected,
                      'text-lg py-3': isMobileView,
                    }"
                    :data-cy-menu-item="item.id"
                    :href="item.href"
                    :target="item.href ? '_blank' : '_self'"
                    :to="item.to"
                    v-on="item.listeners ?? {}"
                    @click="close"
                  >
                    <div
                      v-if="item.icon || $slots[`prepend_${item.id}`]"
                      class="w-5 h-5"
                    >
                      <app-icon
                        v-if="item.icon"
                        class="text-subtle transition"
                        :class="{ '!text-primary': active }"
                        :icon="item.icon"
                        :size="20"
                      />
                    </div>

                    <div>
                      <p class="whitespace-nowrap">{{ item.label }}</p>
                      <p
                        v-if="item.description"
                        class="text-subtle text-xs font-normal transition"
                      >
                        {{ item.description }}
                      </p>
                    </div>

                    <div
                      v-if="item.appendIcon || $slots[`append_${item.id}`]"
                      class="flex-auto flex justify-end"
                    >
                      <slot :name="`append_${item.id}`">
                        <app-icon
                          v-if="item.appendIcon"
                          :icon="item.appendIcon"
                          :size="16"
                        />
                      </slot>
                    </div>
                  </component>
                </menu-item>
              </template>
            </div>
          </menu-items>
        </transition>
      </teleport>
    </client-only>
  </menu-root>
</template>

<script lang="ts" setup>
import {
  Menu as MenuRoot,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";

import type { DropdownPosition } from "../composables/dropdown-position.hook";
import { useDropdownPosition } from "../composables/dropdown-position.hook";
import type { DropdownMenuItem } from "../dropdown.model";

const properties = withDefaults(
  defineProps<{
    items?: DropdownMenuItem[];
    position?: DropdownPosition;
    iconSize?: number;
    buttonClass?: string;
    menuMinWidth?: number;
  }>(),
  {
    items: () => [],
    position: "bottom-left",
    iconSize: 20,
    buttonClass: undefined,
    menuMinWidth: 280,
  },
);

defineSlots<{
  default: (props: { open: boolean }) => void;
  prepend: () => void;
  [key: `append_${string}`]: () => void;
  [key: `prepend_${string}`]: () => void;
}>();

const NuxtLink = resolveComponent("nuxt-link");
const { width } = useWindowSize();
const isMobileView = computed(() => width.value < 768);
const { class: positionClasses } = useDropdownPosition(properties.position);
</script>
